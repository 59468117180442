import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AsmassRXTableColums } from "./components/AdmassRXTableColums";
import AdmassRXHeady from "./components/AdmassRXHeady";
import { http } from "../../../helper/http";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import Switch from '@mui/material/Switch';



const baseUrl = process.env.REACT_APP_BASE_URL;

const AdmassRX = () => {
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);




  const fetchData = async () => {
    try {
      const response: any = await http(`${baseUrl}/admassrx`);
      console.log("table data", response);
      if (response && response.admassRx && response.admassRx.records) {
        setRows(response.admassRx.records);
      }
    } catch (error) {
      console.error("Error fetching AdmassRX data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatTimestamp = (timestamp: string | null) => {
    if (!timestamp) return "N/A";
    return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleRowClick = (id: number) => {
    if (expandedRow === id) {
      setExpandedRow(null);
    } else {
      setExpandedRow(id);
    }
  };

  const handleDeleteRow = async (id: number) => {
    try {
      await http(`${baseUrl}/admassrx?id=${id}`, 'DELETE');
      fetchData();
      toast.success("AdmassRx Deleted Successfully");
    } catch (error) {
      console.error("Error deleting row:", error);
    }
  };

  const handleStatus = async (id: number, newStatus: boolean) => {
    try {
      await http(`${baseUrl}/admassrx`, "PATCH", { id, status: newStatus });
      fetchData();
      if (newStatus) {
        toast.success("Status updated to Active");
      } else {
        toast.error("Status updated to Inactive");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <AdmassRXHeady />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              <Table className="min-w-750 bg-white" aria-labelledby="tableTitle" size="medium">
                <AsmassRXTableColums />
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <React.Fragment key={row.imei}>
                      <TableRow
                        hover
                        className="cursor-pointer"
                        onClick={(e) => {
                          if (!(e.target as HTMLElement).closest(".prevent-expand")) {
                            handleRowClick(row.id);
                          }
                        }}
                      >
                        <TableCell align="center" className="py-3">
                          {row?.imei || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="py-3">
                          {row?.device?.deviceName || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="py-3">
                          {formatTimestamp(row?.lastPublishTime)}
                        </TableCell>
                        <TableCell align="center" className="py-3">
                          <Switch
                            checked={row?.status}
                            onChange={(e) => {
                              e.stopPropagation();
                              const newStatus = !row.status;
                              handleStatus(row.id, newStatus);
                            }}
                            className="prevent-expand"
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>

                        <TableCell align="center">
                          <DeleteOutlineIcon
                            className="cursor-pointer text-red-500 hover:text-red-700"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteRow(row.id);
                            }}
                          />
                        </TableCell>

                      </TableRow>

                      {/* Expandable Row for listOfConfig */}
                      {expandedRow === row.id && row.listOfConfig?.length > 0 && (
                        <TableRow>
                          <TableCell colSpan={5} className="bg-gray-100">
                            <Box p={2}>
                              <strong>Configuration Details:</strong>
                              <Table size="small">
                                <TableBody>
                                  {row.listOfConfig.map((config: any) => (
                                    <TableRow key={config.imei}>
                                      <TableCell>IMEI: {config.imei}</TableCell>
                                      <TableCell>Tag ID: {config.tagId}</TableCell>
                                      <TableCell>Tag Name: {config.tagname}</TableCell>
                                      <TableCell>Device ID: {config.deviceId}</TableCell>
                                      <TableCell>Register Number: {config.registerNumber}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>

              </Table>
            </TableContainer>
          </div>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            page={Math.max(
              0,
              Math.min(page, Math.ceil(rows.length / rowsPerPage) - 1)
            )}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>
      </Box>
    </>
  );
};

export default AdmassRX;