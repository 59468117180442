import axios from "axios";

const loadTime = 0;

interface HttpErrorResponse {
  status?: number;
  data?: {
    message?: string;
  };
}

interface HttpError extends Error {
  response?: HttpErrorResponse;
}

const exportExcel = async (
  url: string,
  onProgress: (progress: number) => void,
  method: "GET" | "POST" = "GET",
  body: Record<string, unknown> | FormData | null = null,
  customFileName?: string
): Promise<void> => {
  const token = localStorage.getItem("token") || "";

  if (!token) {
    window.localStorage.removeItem("token");
    window.location.href = "/";
    return;
  }

  try {
    const response = await axios({
      url,
      method,
      data: method === "POST" ? body : undefined,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      onDownloadProgress: (progressEvent) => {
        const totalBytes = progressEvent.total;
        const receivedBytes = progressEvent.loaded;
        if (totalBytes) {
          const progress = Math.round((receivedBytes / totalBytes) * 100);
          onProgress(progress);
        }
      },
    });

    const contentDisposition = response.headers["content-disposition"];

    const filename =
      customFileName ||
      (contentDisposition
        ? contentDisposition.split("filename=")[1].replace(/"/g, "")
        : "data"); // Default filename

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    const link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);

    link.download = filename;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  } catch (err: unknown) {
    const error = err as HttpError;

    const { status, data } = error.response || {};

    if (method === "GET" && status === 401) {
      window.localStorage.removeItem("token");

      window.location.href = "/";
    }

    return new Promise((_, reject) =>
      setTimeout(
        () => reject(data?.message || "An unexpected error occurred"),

        loadTime
      )
    );
  }
};

export default exportExcel;
