import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    useMemo,
  } from "react";


  import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
  } from "@mui/material";
  import { AspectRatio } from "@mui/icons-material";

  // import { io } from "socket.io-client";

  import axios from "axios";
  import FilterListIcon from "@mui/icons-material/FilterList";
 
  import dayjs, { Dayjs } from "dayjs";

  import EditIcon from "@mui/icons-material/Edit";
  import CloseIcon from "@mui/icons-material/Close";
//   import { filterDataFromEachHourLast24Hours, filterDataFromEachHourLast24HoursTotalizer, generateCSV1, processConsumptionData, processConsumptionDataTotalizer, processHourlyData, processHourlyDataTotalizer, reportDataWithGraph } from "../../common-function/CommonFunction";

  import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DatePicker } from "@mui/x-date-pickers";
import DynamicCard from "../../../components/waterDashboardComponents/DynamicCard";
import SignalStrength from "../../../components/waterDashboardComponents/SignalStrength";
import CheckBoxFilter from "../../../components/CheckboxField";
import TabularComponents from "../../../components/deviceComponents/TabularComponents";
import LongMenu from "../../../utils/longMenu/LongMenu";
import LineChart3 from "../../../components/dashBoardComponents/stepAfterchart3";
import CustomBarChart from "../../../components/dashBoardComponents/BarChart";
import MotorFlowChart from "../MotorFlowChart";
import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import { filterDataFromEachHourLast24HoursTotalizer, generateCSV1, processConsumptionDataTotalizer, processHourlyDataTotalizer, reportDataWithGraph } from "../../../common-function/CommonFunction";
// import { generateCSV1 } from "../../../common-function/Report";

  
  let IO = null;
  const headers = [
    "TIMESTAMP",
    "FLOW",
    "FLOW UNIT",
    "TOTALIZER",
    "TOTALIZER UNIT",
  ];
  
  interface FormData {
    borewellId: string;
    meterSerialNo: string;
    lastCalibrationDate: Dayjs | null;
    dateOfInstallation: Dayjs | null;
    emailId: string;
  }
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const CGWA_2 = ({
    devicesProps,
    selectedDevice,
  }: any) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [filterTable, setFilterTable] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);
  
    const [startValue, setStartValue]: any = React.useState<Dayjs | null>(
      dayjs("")
    );
    const [endValue, setEndValue]: any = React.useState<Dayjs | null>(dayjs(""));
  
    const socketInitData: any = {
      timestamp: "N/A",
      flow: "0",
      flowunit: "",
      totalizer: "0",
      totalizerunit: "",
      rssi: 0,
    };
    const [dashboardSocketData, setDashboardSocketData] =
      useState(socketInitData);
    const [apiData, setApiData] = useState<Row[]>([]);
    const { devices } = useDeviceState();
    const chartRef1 = useRef<HTMLDivElement>(null);
  
    let device: any;
    if (devices.devices.length > 0) {
      device = devices.devices.find(
        (device: { id: any }) => device.id === selectedDevice
      );
    }
  
  
    interface Row {
      timestamp: string;
      flow: string;
      flowunit: string;
      totalizer: string;
      totalizerunit: string;
      [key: string]: string;
    }
    interface FlowData {
      x: string;
      y: number;
    }
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    const toggleFilterList = () => {
      setFilterTable(!filterTable);
    };
  
    const handleSelectedDataChange = (selectedData: string[]) => {
      setSelectedHeaders(selectedData);
    };
  
    //let dynamicFlowData: FlowData[] = [];
  
    let dynamicFlowData: { x: string; y: number }[] = [];
  
    if (Array.isArray(apiData) && apiData.length > 0) {
      const currentDate = new Date();
      const last24HoursDate = new Date(
        currentDate.getTime() - 24 * 60 * 60 * 1000
      );
      const last24HoursData = apiData.filter((entry) => {
        const entryDate = new Date(entry.timestamp);
        return entryDate >= last24HoursDate && entryDate <= currentDate;
      });
  
      const currentHour = currentDate.getHours();
      const startingHour = (currentHour - 23 + 24) % 24;
      dynamicFlowData = Array.from({ length: 24 }, (_, index) => {
        const hour = (startingHour + index) % 24;
  
        const formattedHour = hour.toString().padStart(2, "0");
  
        const flowEntry = last24HoursData.find((entry) => {
          const entryDate = new Date(entry.timestamp);
          return entryDate.getHours() === hour;
        });
  
        const flow = flowEntry ? parseFloat(flowEntry.flow) : 0;
        return { x: `${formattedHour}:00`, y: flow };
      });
    } else {
      console.error("apiData is empty.");
    }
  
    const formatTimestamp = (timestamp: any) => {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
  
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
  
    const fetchData = async () => {
      const accessToken = localStorage.getItem("token");
  
      let response: any = null;
      try {
        response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
          params: {
            deviceId: selectedDevice,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        // console.log("response data", response.data);
  
        const _data = response.data.map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            flow: d["Flow"] !== "ERROR" ? d["Flow"] : "ERROR",
            flowunit: "m³/hr",
            totalizer: d["Totalizer"] !== "ERROR" ? d["Totalizer"] : "ERROR",
            totalizerunit: "m³",
            rssi: Math.round((+d.rssi / 35) * 110),
          };
        });
  
        setApiData(_data);
        setDashboardSocketData(_data.length ? _data[0] : socketInitData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      fetchData();
      const intervalId = setInterval(fetchData, 120000);
      return () => clearInterval(intervalId);
    }, [selectedDevice]);
  
    function getSanitizedValue(value: any) {
      if (typeof value === "number") {
        return value.toFixed(3);
      } else {
        return value;
      }
    }
  
    // console.log("Get flowrate linechart", flowrateLinechartData);
  
    function prepareFlowGraphData() {
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() - 12);
  
      const data: any = apiData.filter(
        (d: any) => new Date(d.timestamp) >= currentDate
      );
    }
  
    useEffect(() => {
      prepareFlowGraphData();
    }, [apiData]);
  
    const chartRef = useRef<HTMLDivElement>(null);
  
    const currentDate = new Date(); // Declare currentDate here
  
    // Filter and sort the data
  
    const last24HoursDataOriginal = apiData.filter((entry) => {
      const entryDate = new Date(entry.timestamp);
      const timeDifference = currentDate.getTime() - entryDate.getTime();
      return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
    });
  
    const last24HoursData = last24HoursDataOriginal.map((entry) => ({
      timestamp: entry.timestamp,
      flow: entry.flow,
      "flow unit": entry.flowunit,
      totalizer: entry.totalizer,
      "totalizer unit": entry.totalizerunit,
    }));
  
    let consumtionData: any[] | undefined;
    const filterLast24hourDataOfEachHOur =
    filterDataFromEachHourLast24HoursTotalizer(apiData);
    if (filterLast24hourDataOfEachHOur.length > 0) {
      const hourData = processHourlyDataTotalizer(filterLast24hourDataOfEachHOur);
      consumtionData = processConsumptionDataTotalizer(hourData);
    
    }
    
  
    last24HoursData.sort(
      (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    );
  
    const last24HoursDataForFlowChart = last24HoursDataOriginal.map((entry) => ({
      timestamp: entry.timestamp,
      flow: entry.flow,
      "flow unit": entry.flowunit,
    }));
  
    const handleExportBarChart = async (exportOption: string) => {
      let filterCosumption: any = consumtionData?.map(
        ({ consumption, firstTimeStamp, hour, lastTimeStamp }: any) => {
          return {
            Hour: hour + ":00",
            "First Timestamp": firstTimeStamp,
            "Last Timestamp": lastTimeStamp,
            Consumption: consumption,
          };
        }
      );
      let extension, content;
      switch (exportOption) {
        case "Download as CSV":
          generateCSV1(filterCosumption);
          break;
        case "XLS":
          extension = "xls";
          content = "data:application/vnd.ms-excel;charset=utf-8,";
          if (!consumtionData || consumtionData.length === 0) {
            content += "No Data Available\n";
          } else {
            // Adding headers
            const headersXLS = Object.keys(consumtionData[0]);
            content +=
              "<table><tr>" +
              headersXLS.map((header) => `<th>${header}</th>`).join("") +
              "</tr>";
  
            // Adding data
            content += consumtionData
              .map(
                (entry) =>
                  "<tr>" +
                  headersXLS
                    .map((header) => `<td>${entry[header]}</td>`)
                    .join("") +
                  "</tr>"
              )
              .join("");
            content += "</table>";
          }
          break;
  
        case "Download as PDF":
          if (consumtionData && chartRef) {
            reportDataWithGraph(
              chartRef,
              filterCosumption as any,
              device?.deviceName,
              device?.customerName,
              "Water Quality Summary",
              "Realtime - Last 24 Hour | IST"
            );
          } else {
          }
          return;
        default:
          return; // Unknown export option
      }
    };
  
    const handleExportLineChart = async (exportOption: string) => {
      switch (exportOption) {
        case "Download as CSV":
          handleExportToExcelForFlowChart();
          break;
  
        case "Download as PDF":
          if (last24HoursDataForFlowChart && chartRef1) {
            reportDataWithGraph(
              chartRef1,
              last24HoursDataForFlowChart,
              device?.deviceName,
              device?.Customer?.name,
              "Water Quality Summary",
              "Realtime - Last 24 Hour | IST"
            );
          } else {
          }
          return;
        default:
          return;
      }
    };
  
    const handleExportToExcel = () => {
      const csvContent = "data:text/csv;charset=utf-8,";
      const headers = Object.keys(last24HoursData[0]);
      const headerCsv = headers.join(",");
      const dataRows = last24HoursData
        .map((entry: { [key: string]: string }) => {
          return headers
            .map((col) => (entry && entry[col] ? entry[col] : ""))
            .join(",");
        })
        .join("\n");
      const csvExport = headerCsv + "\n" + dataRows;
      const encodedUri = encodeURI(csvContent + csvExport);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    const handleExportToExcelForFlowChart = () => {
      const csvContent = "data:text/csv;charset=utf-8,";
      const headers = Object.keys(last24HoursDataForFlowChart[0]);
      const headerCsv = headers.join(",");
      const dataRows = last24HoursDataForFlowChart
        .map((entry: { [key: string]: string }) => {
          return headers
            .map((col) => (entry && entry[col] ? entry[col] : ""))
            .join(",");
        })
        .join("\n");
      const csvExport = headerCsv + "\n" + dataRows;
      const encodedUri = encodeURI(csvContent + csvExport);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    const [showPopup, setShowPopup] = useState(false);
  
    // const handleIconClick = () => {
    //   setShowPopup(true);
    // };
  
    useEffect(() => {
      prepareFlowGraphData();
    }, [apiData]);
  
    const [formData, setFormData] = useState<FormData>({
      borewellId: "",
      meterSerialNo: "",
      lastCalibrationDate: null,
      dateOfInstallation: null,
      emailId: "",
    });
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
  
    const handleDateChange =
      (name: "lastCalibrationDate" | "dateOfInstallation") =>
      (date: Dayjs | null) => {
        setFormData((prevFormData) => ({ ...prevFormData, [name]: date }));
      };
  
    const handleSaveClick = () => {
      const formattedData = {
        ...formData,
        lastCalibrationDate:
          formData.lastCalibrationDate?.format("YYYY-MM-DD") || null,
        dateOfInstallation:
          formData.dateOfInstallation?.format("YYYY-MM-DD") || null,
      };
      localStorage.setItem("installationDetails", JSON.stringify(formattedData));
      setShowPopup(false);
    };
  
    const handleIconClick = () => {
      // Load existing data from localStorage when opening the popup
      const existingData = JSON.parse(
        localStorage.getItem("installationDetails") || "{}"
      );
      setFormData({
        borewellId: existingData.borewellId || "",
        meterSerialNo: existingData.meterSerialNo || "",
        lastCalibrationDate: existingData.lastCalibrationDate
          ? dayjs(existingData.lastCalibrationDate)
          : null,
        dateOfInstallation: existingData.dateOfInstallation
          ? dayjs(existingData.dateOfInstallation)
          : null,
        emailId: existingData.emailId || "",
      });
      setShowPopup(true);
    };
  
    const handleCloseClick = () => {
      setShowPopup(false);
    };
  
    const installationDetails = JSON.parse(
      localStorage.getItem("installationDetails") ?? "{}"
    );
  
    return (
      <>
        <div className="flex flex-col md:flex-row ">
          <div className="w-full md:w-1/2 h-full p-2">
            <div className="h-1/2 overflow-auto flex border border-solid border-gray-300 rounded p-4">
              <div className="w-auto md:w-1/5 h-[302px] ">
                <DynamicCard
                  customComponent={
                    <SignalStrength percentage={dashboardSocketData.rssi} />
                  }
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="flex-1 ml-2">
                <div className="flex">
                  <DynamicCard
                    cardTitle="Flow"
                    cardValue={getSanitizedValue(dashboardSocketData.flow) as any}
                    textColor="text-blue-400"
                    width="70%"
                  />
                  <DynamicCard
                    unit="m³/hr"
                    textColor="text-blue-400"
                    width="30%"
                  />
                </div>
                <div className="flex">
                  <DynamicCard
                    cardTitle="Totalizer"
                    cardValue={
                      getSanitizedValue(dashboardSocketData.totalizer) as any
                    }
                    textColor="text-purple-400"
                    width="70%"
                  />
                  <DynamicCard
                    unit="m³"
                    textColor="text-purple-400"
                    width="30%"
                  />
                </div>
              </div>
            </div>
            <div className="h-[705px] overflow-auto shadow-xl mt-4 z-11 border border-solid border-gray-300 rounded px-4">
              <div className="flex items-center sticky top-0 bg-white py-1">
                <p className="font-semibold text-lg text-slate-600">
                  Last 24 Hours Meta Data Reading
                </p>
                <div className="ml-auto mt-4 xl:mt-0 ">
                  <IconButton onClick={toggleFilterList}>
                    <FilterListIcon />
                  </IconButton>
                  <Dialog
                    open={filterTable}
                    onClose={() => {
                      setFilterTable(false);
                    }}
                  >
                    <CheckBoxFilter
                      data={headers}
                      onSelectedDataChange={handleSelectedDataChange}
                    />
                  </Dialog>
                  <IconButton onClick={handleExportToExcel}>
                    <ArrowCircleDownIcon />
                  </IconButton>
                  <IconButton onClick={toggleExpand}>
                    <AspectRatio />
                  </IconButton>
                </div>
              </div>
              {isExpanded ? (
                <Dialog maxWidth="xl" open={true} onClose={toggleExpand}>
                  <DialogTitle className="font-semibold text-lg text-slate-600 ">
                    Last 24 Hours Meta Data Reading
                  </DialogTitle>
                  <DialogContent>
                    <TabularComponents
                      headers={headers}
                      rows={last24HoursData}
                      sortableColumns={["TIMESTAMP"]}
                    />
                  </DialogContent>
                </Dialog>
              ) : (
                <></>
              )}
  
              <TabularComponents
                headers={last24HoursData.length > 0 ? selectedHeaders : headers}
                rows={last24HoursData}
                sortableColumns={["TIMESTAMP"]}
              />
            </div>
          </div>
  
          <div className="w-full md:w-1/2 p-2">
            <div
              ref={chartRef1}
              className=" overflow-auto shadow-xl flex flex-col 
              items-center justify-center border border-solid border-gray-300 rounded px-2
               h-[380px]"
              style={{ boxShadow: "0 0 11px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="flex relative  items-center justify-between w-full">
                <h1 className="relative px-2 h-fit w-full ">
                  <p className="font-semibold text-lg text-slate-600">
                    Last 24 Hours Flowrate Summary
                  </p>
                </h1>
                <div className="mt-4">
                  <LongMenu
                    options={["Download as CSV", "Download as PDF"]}
                    onOptionClick={handleExportLineChart}
                    id={1}
                  />
                </div>
              </div>
              <LineChart3 height="200" apiData={apiData} />
            </div>
  
            <div
              className="overflow-hidden mt-2 border border-solid border-gray-300 rounded p-4"
              style={{
                boxShadow: "0 0 11px rgba(0, 0, 0, 0.1)",
                maxHeight: "380px",
                minHeight: "380px",
              }}
            >
              <div className="flex relative  items-center justify-between w-full">
                <h1 className="relative px-2 h-fit w-full ">
                  <p className="font-semibold text-lg text-slate-600">
                    Last 24 Hours Totalizer Summary
                  </p>
                </h1>
                <div className="mt-4">
                  <LongMenu
                    options={["Download as CSV", "Download as PDF"]}
                    onOptionClick={handleExportBarChart}
                    id={1}
                  />
                </div>
              </div>
  
              <div ref={chartRef} className="h-[400px]">
              <CustomBarChart data={consumtionData} />
              </div>
            </div>
  
            <div className="grid md:grid-cols-2 mt-2 gap-4">
              <div className="bg-white shadow-md py-4 border border-solid border-gray-300">
                <div className="px-4 text-[#333333]">
                  <div className="flex">
                    <div className="w-[90%] h-auto">
                      <h2 className="text-lg font-bold">Installation Details</h2>
                    </div>
                    <div className="cursor-pointer" onClick={handleIconClick}>
                      <EditIcon />
                    </div>
                    {showPopup && (
                      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-6 rounded shadow-lg relative">
                          <div>
                            <div className="flex">
                              <div className="w-[96%]">
                                <p className="text-lg font-sans-serif text-[20px]">
                                  Edit Installation Details
                                </p>
                              </div>
                              <div className="flex justify-end">
                                <button
                                  className="text-gray-500 hover:text-gray-800"
                                  onClick={handleCloseClick}
                                >
                                  <CloseIcon />
                                </button>
                              </div>
                            </div>
  
                            <div className="mt-3">
                              <TextField
                                name="borewellId"
                                label="Borewell ID"
                                value={formData.borewellId}
                                onChange={handleChange}
                                className="mt-2 w-full"
                              />
  
                              <div className="flex gap-4 mt-2">
                                <TextField
                                  name="meterSerialNo"
                                  label="Meter Serial No"
                                  value={formData.meterSerialNo}
                                  onChange={handleChange}
                                  className="mt-2 w-full"
                                />
                              </div>
  
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="flex gap-4 mt-2">
                                  <DatePicker
                                    label="Last Calibration Date"
                                    value={formData.lastCalibrationDate}
                                    onChange={handleDateChange(
                                      "lastCalibrationDate"
                                    )}
                                  />
                                  <DatePicker
                                    label="Date Of Installation"
                                    value={formData.dateOfInstallation}
                                    onChange={handleDateChange(
                                      "dateOfInstallation"
                                    )}
                                  />
                                </div>
                              </LocalizationProvider>
  
                              <div className="mt-2">
                                <TextField
                                  name="emailId"
                                  label="Email ID"
                                  value={formData.emailId}
                                  onChange={handleChange}
                                  className="mt-2 w-full"
                                />
                              </div>
  
                              <div className="mt-14 flex justify-end">
                                <button
                                  className="border py-2 px-4 rounded-md font-sans-serif text-[14px]"
                                  onClick={handleCloseClick}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="border py-2 px-6 rounded-md ml-4 font-sans-serif text-[14px]"
                                  onClick={handleSaveClick}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
  
                  <div className="flex mt-1 ">
                    <span className="block text-xs font-medium leading-8">
                      BOREWELL ID:
                    </span>
                    <span className="block text-xs font-medium leading-8 ml-auto">
                      {installationDetails?.borewellId}
                    </span>
                  </div>
  
                  <div className="flex">
                    <span className="block text-xs font-medium leading-8">
                      METER SERIAL NO:
                    </span>
                    <span className="block text-xs font-medium leading-8 ml-auto">
                      {installationDetails?.meterSerialNo}
                    </span>
                  </div>
  
                  <div className="flex">
                    <span className="block text-xs font-medium leading-8">
                      LAST CALIBRATION DATE:
                    </span>
                    <span className="block text-xs font-medium leading-8 ml-auto">
                      {installationDetails?.lastCalibrationDate}
                    </span>
                  </div>
  
                  <div className="border w-full"></div>
  
                  <div className="flex">
                    <span className="block text-xs font-medium leading-8">
                      DATE OF INSTALLATION:
                    </span>
                    <span className="block text-xs font-medium leading-8 ml-auto">
                      {installationDetails?.dateOfInstallation}
                    </span>
                  </div>
  
                  <div className="flex">
                    <span className="block text-xs font-medium leading-8">
                      EMAIL ID:
                    </span>
                    <span className="block text-xs font-medium leading-8 ml-auto">
                      {installationDetails?.emailId}
                    </span>
                  </div>
                </div>
              </div>
  
              <div className="p-4 bg-white shadow-md border border-solid border-gray-300">
                <h2 className="text-lg font-bold">Motor Running Status</h2>
                <div>
                  <MotorFlowChart apiData={apiData} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Dashboard1 /> */}
      </>
    );
  };
  
  export default CGWA_2;
  