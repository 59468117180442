import { Toolbar, Typography } from '@mui/material';
const AdmassRXHeady = () => {
  return (
    <Toolbar
    sx={{
      pl: { sm: 2 },
      pr: { xs: 1, sm: 1 },
    }}
  >
    <Typography
      sx={{
        flex: "1 1 100%",
        fontWeight: 700,
        fontSize: [
          "text-sm",
          "text-md",
          "text-lg",
          "text-xl",
          "text-2xl",
          "text-3xl",
        ],
      }}
      variant="h6"
      id="tableTitle"
      component="div"
      className="shadow-gray-500 text-black font-bold"
    >
      AdmassRx
    </Typography>
    <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
      <input
        type="text"
        placeholder="Search"
        onChange={()=>{}}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // handleFilterSubmit();
          }
        }}
        className="border border-black rounded p-1 w-full sm:w-auto"
      />
    </div>
  </Toolbar>
  )
}

export default AdmassRXHeady;
