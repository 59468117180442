import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { http } from "../../../helper/http";
import { FileDownload } from "@mui/icons-material";
import exportExcel from "../../../utils/export/excel-export";

const tableCellsData = [
  { label: "IMEI No", id: "deviceId" },
  { label: "Device Name", id: "deviceName" },
  { label: "Device Alias", id: "deviceAlias" },
  { label: "Customer Name", id: "customerName" },
  { label: "RSSI", id: "rssi" },
  { label: "Date Time", id: "timeStamp" },
  { label: "Status", id: "adminDeviceStatus" },
];

interface DeviceStatusAttributes {
  id: number;
  deviceId: string;
  deviceName: string;
  template: number;
  status: boolean;
  latitude: number;
  longitude: number;
  customerId?: string;
  deviceAlias: string;
  adminDeviceStatus: boolean;
  lastDataPoint?: {
    id: number;
    rssi: number;
    timeStamp: string;
  };
  Customer?: {
    name: string;
  };
}

export interface ApiResponseDeviceStatus {
  devices: {
    devices: DeviceStatusAttributes[];
    currentPage: number;
    totalPages: number;
    totalItems: number;
  };
}

const baseUrl = process.env.REACT_APP_BASE_URL;

function DeviceStatus() {
  const location = useLocation();
  const status = location.state;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [devices, setDevices] = useState<ApiResponseDeviceStatus | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [downloadProgress, setDownloadProgress] = useState<number>(0);

  const fetchDevices = async (page: number) => {
    setLoading(true);
    try {
      const response = await http(
        `${baseUrl}/admin-device-status?status=${
          status.status
        }&search=${searchQuery}&page=${page + 1}`
      );
      const responseData = response as ApiResponseDeviceStatus;
      setDevices(responseData);
    } catch (err) {
      console.error("Error fetching devices:", err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleExport = async () => {
    try {
      let queryParams = "/admin-device-status-export?status=" + status.status;
      if (searchQuery) {
        queryParams += `&search=${searchQuery}`;
      }

      await exportExcel(
        baseUrl + queryParams,
        (progress) => {
          setDownloadProgress(progress);
        },
        "GET",
        {},
        "devices-status.xlsx"
      );

      setDownloadProgress(0);
    } catch (error) {
      setDownloadProgress(0);
    } finally {
      setDownloadProgress(0);
    }
  };

  useEffect(() => {
    fetchDevices(page);
  }, [searchQuery, page]);

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Paper sx={{ width: "99%", padding: "10px" }}>
        <div className="flex justify-between items-center">
          <Typography
            sx={{
              flex: "1 1 100%",
              fontWeight: 700,
              fontSize: [
                "text-sm",
                "text-md",
                "text-lg",
                "text-xl",
                "text-2xl",
                "text-3xl",
              ],
            }}
            variant="h6"
            id="tableTitle"
            component="div"
            className="shadow-gray-500 text-black font-bold text-decoration-uppercase capitalize"
          >
            {status.status} devices
          </Typography>
          <div className="flex w-1/2">
            <input
              type="search"
              placeholder="Search..."
              name="search"
              value={searchQuery}
              onChange={(event) => {
                setSearchQuery(event.target.value);
                setPage(0);
              }}
              className="border border-black rounded p-1 w-full sm:w-auto"
            />
            <div className="ml-2">
              <button
                onClick={handleExport}
                className="px-4 py-2 bg-[#117DAE] text-white rounded-3xl text-sm hover:bg-[#0e72a0]"
              >
                <FileDownload style={{ fontSize: 25, marginRight: "2px" }} />
                Export Data {downloadProgress > 0 && `(${downloadProgress}%)`}
              </button>
            </div>
          </div>
        </div>
        <Box className="py-4">
          <div className="h-[60vh] overflow-y-auto">
            <Table
              className="min-w-750 bg-white"
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead className="bg-gray-800">
                <TableRow className="border-b">
                  {tableCellsData.map((cellData) => (
                    <TableCell
                      key={cellData.id}
                      padding="normal"
                      className="p-2"
                      sx={{
                        color: "white",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {cellData.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center" }}>
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  devices?.devices?.devices?.map(
                    (device: DeviceStatusAttributes) => (
                      <TableRow key={device.id}>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {device.deviceId}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {device.deviceName}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {device.deviceAlias}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {device.Customer?.name}
                        </TableCell>

                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {device.lastDataPoint?.rssi}
                        </TableCell>

                        <TableCell
                          style={{ textAlign: "center", padding: "10px" }}
                        >
                          {moment
                            .utc(device.lastDataPoint?.timeStamp)
                            .tz("Asia/Kolkata")
                            .format("YYYY-MM-DD H:mm:ss")}
                        </TableCell>

                        <TableCell
                          className="text-center"
                          style={{ textAlign: "center" }}
                        >
                          <span
                            className={`text-${
                              device.adminDeviceStatus ? "green-600" : "red-600"
                            } 
                            ${
                              device.adminDeviceStatus
                                ? "bg-green-200"
                                : "bg-red-100"
                            } 
                            rounded-md 
                            ${
                              device.adminDeviceStatus
                                ? "px-4 py-2"
                                : "px-3 py-2"
                            } `}
                          >
                            {device.adminDeviceStatus ? "Active" : "Inactive"}
                          </span>
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            count={devices?.devices?.totalItems || 0}
            rowsPerPage={10}
            page={page}
            onPageChange={handlePageChange}
            className="bg-gray-100"
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default DeviceStatus;
