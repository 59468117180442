import { TableCell, TableHead, TableRow } from "@mui/material";

interface Data {
  imei: string;
  deviceName:string;
  lastPublishTime: string;
  status:boolean;
  action: string;
}

const headCells: { id: keyof Data; disablePadding: boolean; label: string }[] =
  [
    {
      id: "imei",
      disablePadding: true,
      label: "IMEI",
    },
    {
        id: "deviceName",
        disablePadding: true,
        label: "Device Name",
      },
    {
      id: "lastPublishTime",
      disablePadding: false,
      label: "Last Public Time",
    },
    {
      id: "status",
      disablePadding: false,
      label: "Status",
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action",
    },
  ];

export function AsmassRXTableColums() {
  return (
    <TableHead className="bg-gray-800">
      <TableRow className="border-b">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="normal"
            sx={{ fontWeight: 600, color: "white" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
